.float-row:hover
{
    /* border-bottom: 1px solid #00b5f0; */
    cursor:pointer;
}
.upload-div {
    height:300px;
    width:250px;
    border: 1px solid #707070;
    margin-bottom: 35px;
}
.image-holder
{
    height:150px;
    width:150px;
}

.td-setter {
    white-space: nowrap !important;
    height: 150px;
}

.reset-td {
    white-space: pre-wrap !important;
}

.qrLoader {
  align-items: center;
  justify-content: center;
}

.imageHolder
{
    max-width: 100%;
    max-width: 100%;
}

.section-details
{
    position: fixed;
    top: 116px;
    width: 250px;
    
    left: 0px;
    color: white;
}

.section-details td {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
