.bg-image
{
    /* background: linear-gradient(rgba(142,218,209, 0.44),rgba(142,218,209, 0.44)), url("../assets/img/theme/bg.png"); */
    background: url("../assets/img/theme/bg.png") 40% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
}

.bg-primary-grad
{
    background: linear-gradient( rgba(202, 234, 230, 1) -93%,rgba(7, 170, 151, 1) 93%);
    background-repeat: no-repeat;
    background-size: cover;
}


.border-style
{
    object-fit: contain;
    border-radius: 35px;
    border: solid 1px #003e52;
    background-color: #ffffff;
    padding: 0.5rem;
}

.btn-border-style
{
    height: 55px;
  object-fit: contain;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  /* background-color: #00b5f0; */
}

.btn-border-style-outline
{
  object-fit: contain;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.form-control
{
    height: inherit;
    padding: 0px;
}


@media (max-width: 767px) {
    .bg-image{
        background-position: top left;
    }
  }
@media (max-width: 991px) {
    .bg-image{
        background: url("../assets/img/theme/bg.png") 50% 100%;
        background-size: cover;
    }
  } 
  