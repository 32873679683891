.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.showpointer:hover {
  cursor: pointer;
}

.footer {
  position: fixed;
  bottom: 0px;
  width:100%;
  padding-top:2px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-size {
  width: 70%;
  height: 70%;
}


@media (max-width: 991px) {
  .reset-margin {
    margin-top: -80px !important;
  }
  .nav-heading {
    font-size: 20px;
  }
  .break-me
  {
    display:none;
  }
  .page-me
  {
    display:none;
  }
}

@media (max-width:767px) {
  .break-me
  { 
    display:block;
  }
  .page-me
  {
    display:block;
  }
}

@media (max-width:650px) {
  .setmargin {
    margin-left:10px;
  }
  .break-me
  { 
    display:none;
  }
  .page-me
  {
    display:none;
  }
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}
.pagination {
  margin-left:20px;
}
.pagination li a {
  position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    /* color: #00b5f0; */
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}
.pagination li a:hover{
  cursor: pointer;
}

.pagination .active a {
  z-index: 3;
    color: #fff;
    cursor: default;
    /* background-color: #00b5f0; */
    /* border-color: #00b5f0; */
}

.btn-primary.disabled{
  background-color: gray !important;
  border-color: gray !important;
}
.pagination .disabled a {
  color: #777;
  cursor: not-allowed !important;
  background-color: #fff;
  border-color: #ddd;
}

.mt--12
{
  margin-top: -12rem !important;
}

.padding-narrow{
  padding-left: 16px !important;
  padding-right: 16px !important;
}