.no-border{
    border: none !important;
}
.subheader{
    color: #808183
}

.border-radius{
    border-radius:0.375rem
}

td{
    white-space: normal !important;
}

th{

    width: 33%
}