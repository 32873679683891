/* .active{
    background:#259888 !important;
    color: #003e52  !important;

} */

/* .bg-primary-gradient
{
    background-image: linear-gradient(to right, #07aa97 -25%, #199385 86%,
    #17696c 236%)
    background-color: #00b5f0;
} */

.sidebar-nav
{
    font-size: 22px !important;
    /* border-bottom: 1px solid #caeae6; */
    font-weight: bold;
}

.brand-image{
    max-height: 140px;
    line-height:10rem;
    margin: -1rem -1.5rem -2rem;
}

.nav-bar-custom{
    margin-top:-2rem;
}

@media (max-width: 991px) {
    /* .bg-image{
       display:none;
    } */
    .nav-bar-custom{
        margin-top:0;
    }
  }