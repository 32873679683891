.bullet::before
{display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;

}

.float-row:hover
{   
    /* border-bottom: 1px solid #00b5f0; */
    cursor:pointer;
}

.setwidth
{
    width:350px;
    left: -12rem !important;

}


.underline
{
    border-bottom: 1px solid;
    padding-bottom: 2px;
}
.set-border
{
    border-top:5px solid !important;
}