.grid-container
{
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 50px;

}
.custom-card{
    min-height: 150px;
    border: 2px solid white;
    border-radius: 10px;
}

.custom-card:hover{
    border-color: var(--main-primary) !important;
}

.custom-size 
{
    font-size: 20px;
    max-width: 150px;
    min-width: 150px;
    min-height: 60px;
}

@media(max-width:1600px)
{
    .grid-container
    {
        grid-template-columns: auto auto auto;
    }
}

@media(max-width:1270px)
{
    .grid-container
    {
        grid-template-columns: auto auto;
    }
}

@media(max-width:991px)
{
    .grid-container
    {
        grid-template-columns: auto;
    }
}
